import request from "../utils/request";
/**
* get init info
* @returns 
*/
export const getBaseInfo = () => {
   return request<{tvl: number, users: number, yeild: number}>({
       url: `/base/info`,
       method: "GET",
   });
}
