import { IconProps } from '../type'

export const Twitter: React.FC<IconProps> = ({ color = '#fff', width = '.22rem', height ='.18rem' }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" className="twitter-svg" width="22" height="18" viewBox="0 0 22 18" fill="none" style={{ fill: "none", width: width, height: height, visibility: 'visible' }}>
      <path className="svg-border" d="M16.1782 1H18.9571L12.9237 7.77742L20.0168 17H14.4508L10.0947 11.4041L5.08779 17H2.32562L8.77623 9.75486L1.9668 1H7.66637L11.5968 6.11178L16.1782 1ZM15.2102 15.3754H16.7373L6.86526 2.54254H5.19628L15.2102 15.3754Z" fill="white"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M15.4335 0.332584C15.6232 0.120936 15.894 0 16.1782 0H18.9571C19.351 0 19.7082 0.231267 19.8694 0.590683C20.0307 0.950098 19.9659 1.37069 19.704 1.66492L14.221 7.82399L20.8095 16.3904C21.0418 16.6923 21.0823 17.1 20.914 17.4418C20.7457 17.7835 20.3978 18 20.0169 18H14.4508C14.1424 18 13.8512 17.8577 13.6617 17.6143L10.0414 12.9635L5.83303 17.6668C5.64333 17.8788 5.3723 18 5.0878 18H2.32563C1.93169 18 1.57446 17.7687 1.41322 17.4093C1.25199 17.0499 1.3168 16.6293 1.57876 16.335L7.47578 9.71167L1.17746 1.61395C0.942969 1.31247 0.900685 0.90375 1.06849 0.560652C1.23629 0.217553 1.58487 0 1.9668 0H7.66638C7.97692 0 8.26984 0.144271 8.45913 0.390453L11.6557 4.54778L15.4335 0.332584ZM11.5968 6.11178L7.66638 1H1.9668L8.77624 9.75486L2.32563 17H5.0878L10.0948 11.4041L14.4508 17H20.0169L12.9237 7.77742L18.9571 1H16.1782L11.5968 6.11178ZM5.19629 2.54254H6.86527L16.7373 15.3754H15.2102L5.19629 2.54254Z" fill="black"/>
    </svg>
  )
}

export const CakeTwitter: React.FC<IconProps> = ({ width = '.42rem', height ='.41rem' }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" className="cake-twitter-svg" width="42" height="41" viewBox="0 0 42 41" fill="none"  style={{ width: width, height: height, visibility: 'visible' }}>
      <path className='svg-path' d="M40.6811 20.5549C37.6811 38.0549 32.1811 40.5549 20.6812 40.5549C9.18115 40.5549 4.68115 31.5547 0.681149 20.5549C-2.73628 11.1571 7.13446 -2.97885 20.6812 0.554884C32.1812 3.55473 43.6811 8.05489 40.6811 20.5549Z" fill="black"/>
      <path d="M26.7467 11.5547H29.8257L23.1405 19.1793L31 29.5547H24.8326L20.006 23.2593L14.4582 29.5547H11.3976L18.5451 21.4039L11 11.5547H17.3153L21.6704 17.3054L26.7467 11.5547ZM25.6741 27.727H27.3662L16.4276 13.29H14.5784L25.6741 27.727Z" fill="white"/>
    </svg>
  )
}
