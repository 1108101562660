import { IconProps } from '../type'

const Close: React.FC<IconProps> = ({ width = '.16rem', height ='.17rem' }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none" style={{ width: width, height: height }}>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M3.70264 0.406838C3.24859 -0.0976603 2.47153 -0.138558 1.96703 0.31549C1.46254 0.769539 1.42164 1.54659 1.87569 2.05109C3.34348 3.68198 4.70502 5.38839 5.96834 7.17787C5.35923 7.76476 4.76033 8.36323 4.17726 8.97672C3.79252 9.38154 3.29492 9.76411 2.70094 10.2208L2.70081 10.2209C2.57206 10.3199 2.43878 10.4224 2.30114 10.5293C1.59648 11.0768 0.758021 11.7571 0.206533 12.5844C-0.16996 13.1491 -0.017356 13.9121 0.547384 14.2886C1.11212 14.6651 1.87514 14.5125 2.25164 13.9478C2.55324 13.4954 3.08313 13.0343 3.80911 12.4703C3.91673 12.3866 4.02969 12.3001 4.14614 12.2108C4.74166 11.7544 5.42847 11.2281 5.95888 10.67C6.4718 10.1303 6.99394 9.60585 7.52381 9.09173C7.79906 9.37655 8.07333 9.64386 8.33456 9.898L8.36552 9.92811L8.36553 9.92813C9.06814 10.6116 9.67294 11.1999 10.1643 11.8803C11.1642 13.2647 12.2754 14.6823 13.5114 15.9183C13.9914 16.3982 14.7695 16.3982 15.2494 15.9183C15.7294 15.4384 15.7294 14.6602 15.2494 14.1803C14.1474 13.0783 13.1241 11.7804 12.1569 10.4412C11.5376 9.58373 10.796 8.86283 10.1371 8.22239L10.0485 8.13623C9.7921 7.88682 9.54774 7.64748 9.31673 7.40961C10.2262 6.58036 11.1527 5.7648 12.0893 4.9403L12.0894 4.94027C13.0467 4.09759 14.0146 3.24557 14.9858 2.36003C15.4874 1.90274 15.5232 1.12543 15.0659 0.623878C14.6086 0.122324 13.8313 0.0864487 13.3298 0.543748C12.4274 1.36655 11.4979 2.18495 10.5603 3.01049L10.5602 3.01051C9.63555 3.82468 8.70298 4.6458 7.78069 5.48494C6.51713 3.71686 5.16004 2.02617 3.70264 0.406838Z" fill="#1D2738"/>
    </svg>
  )
}

export default Close
