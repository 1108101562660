import { IconProps } from '../type'

const Usdx: React.FC<IconProps> = ({ color = '#fff', width = '1.28rem', height ='.30rem' }) => {
  return (
    <svg width="128" height="30" viewBox="0 0 128 30" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ width: width, height: height }}>
      <path d="M105.736 24.0001L112.168 15.4321L105.736 6.84009H110.416L115.576 13.8721H117.784L122.944 6.84009H127.624L121.192 15.4321L127.624 24.0001H122.92L117.784 16.9921H115.576L110.44 24.0001H105.736Z" fill="white"/>
      <path d="M85.6606 23.9999V6.83992H88.4926L96.1726 6.81592C99.2766 6.81592 101.685 7.54392 103.397 8.99992C105.109 10.4559 105.965 12.5919 105.965 15.4079C105.965 18.2239 105.109 20.3599 103.397 21.8159C101.685 23.2719 99.2766 23.9999 96.1726 23.9999H85.6606ZM95.9086 9.93592H89.5006V20.8799H95.9086C97.9246 20.8799 99.4526 20.4399 100.493 19.5599C101.533 18.6799 102.053 17.2959 102.053 15.4079C102.053 13.5039 101.533 12.1199 100.493 11.2559C99.4526 10.3759 97.9246 9.93592 95.9086 9.93592Z" fill="white"/>
      <path d="M63.429 24.12V21L77.229 21.024C77.933 21.024 78.469 20.864 78.837 20.544C79.221 20.208 79.413 19.672 79.413 18.936C79.413 18.2 79.221 17.672 78.837 17.352C78.469 17.016 77.933 16.848 77.229 16.848H68.637C66.861 16.848 65.477 16.424 64.485 15.576C63.509 14.712 63.021 13.448 63.021 11.784C63.021 10.12 63.509 8.86397 64.485 8.01597C65.477 7.15197 66.861 6.71997 68.637 6.71997H82.125V9.83997L69.045 9.81597C68.357 9.81597 67.821 9.97597 67.437 10.296C67.053 10.6 66.861 11.096 66.861 11.784C66.861 12.472 67.053 12.976 67.437 13.296C67.821 13.6 68.357 13.752 69.045 13.752H77.637C79.413 13.752 80.789 14.192 81.765 15.072C82.757 15.936 83.253 17.224 83.253 18.936C83.253 20.632 82.757 21.92 81.765 22.8C80.789 23.68 79.413 24.12 77.637 24.12H63.429Z" fill="white"/>
      <path d="M47.7607 24.1201C46.0167 24.1201 44.6407 23.8961 43.6327 23.4481C42.6247 22.9841 41.9047 22.2881 41.4727 21.3601C41.0407 20.4321 40.8247 19.2641 40.8247 17.8561V6.84009H44.6647V17.8561C44.6647 19.0721 44.9447 19.9041 45.5047 20.3521C46.0807 20.7841 47.0487 21.0001 48.4087 21.0001H53.0407C54.4007 21.0001 55.3607 20.7841 55.9207 20.3521C56.4967 19.9041 56.7847 19.0721 56.7847 17.8561V6.84009H60.6247V17.8561C60.6247 19.2641 60.4087 20.4321 59.9767 21.3601C59.5447 22.2881 58.8247 22.9841 57.8167 23.4481C56.8087 23.8961 55.4327 24.1201 53.6887 24.1201H47.7607Z" fill="white"/>
      <g clipPath="url(#clip0_302_607)">
      <path d="M30.72 0.00266735C27.3979 0.00266735 24.1651 0.0066618 20.9309 4.37945e-06C20.128 -0.0013271 19.5062 0.30092 19.0242 0.948021C17.5969 2.86669 16.1549 4.77337 14.7182 6.68538C14.0485 7.57881 13.3788 8.4709 12.6997 9.37631C12.6278 9.28577 12.5719 9.21787 12.5186 9.1473C11.1898 7.37642 9.85032 5.61354 8.53748 3.83202C8.09942 3.23817 7.56816 2.95457 6.82119 2.96256C4.68283 2.98785 2.54447 2.97188 0.407434 2.97188H0C0.10785 3.11834 0.175756 3.21421 0.246325 3.30874C3.01981 6.98497 5.79196 10.6625 8.57343 14.3321C8.73454 14.5465 8.74252 14.6756 8.57742 14.8953C5.80527 18.5729 3.04244 22.2584 0.27828 25.9413C0.2157 26.0265 0.157115 26.1144 0.0625798 26.2489H0.470014C2.60705 26.2489 4.74541 26.2396 6.88244 26.2555C7.5615 26.2595 8.06879 26.0092 8.4709 25.4673C9.81171 23.6631 11.1645 21.8696 12.5133 20.0721C12.5692 19.9989 12.6331 19.931 12.7037 19.8471C12.7769 19.9403 12.8328 20.0082 12.8861 20.0774C14.9246 22.7963 16.9684 25.5126 18.9976 28.2368C19.4943 28.9025 20.1254 29.2221 20.9549 29.2194C24.0013 29.2088 27.0478 29.2154 30.0942 29.2154H30.6534C26.9745 24.3142 23.3383 19.4703 19.6953 14.6184C23.3675 9.75179 27.0265 4.9012 30.72 0.00266735ZM25.5685 26.5604C25.6004 26.6031 25.6337 26.6443 25.7123 26.7442C24.1371 26.7442 22.6326 26.7482 21.128 26.7335C21.0175 26.7322 20.8817 26.607 20.8031 26.5045C19.0908 24.233 17.3839 21.9575 15.6769 19.682C14.4626 18.0616 13.2483 16.4398 12.034 14.8181C11.982 14.7475 11.9328 14.6756 11.8755 14.5931C12.7902 13.3748 13.6983 12.1618 14.6077 10.9501C16.6675 8.20727 18.7313 5.46575 20.7858 2.71756C20.9283 2.52716 21.0721 2.45925 21.3064 2.46059C22.7657 2.47124 24.225 2.46591 25.7376 2.46591C25.6617 2.58175 25.6151 2.66297 25.5592 2.73753C22.6472 6.59884 19.7366 10.4628 16.8166 14.3188C16.6502 14.5385 16.6382 14.6756 16.8113 14.906C19.7366 18.7859 22.6512 22.6738 25.5685 26.5604Z" fill="white"/>
      </g>
      <defs>
      <clipPath id="clip0_302_607">
      <rect width="30.72" height="29.2194" fill="white"/>
      </clipPath>
      </defs>
      </svg>
  )
}

export default Usdx
