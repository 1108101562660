import axios, { AxiosRequestConfig } from 'axios'
// import _ from 'lodash'
const HOST = process.env.REACT_APP_API_HOST;

interface MyResponseType<T> {
    code: number
    message: string
    success: boolean
    fail: boolean
    result: T
}

const instance = axios.create({
    baseURL: `${HOST}/v1`,
    timeout: 60000,
    withCredentials: true,
    validateStatus(status) {
        return status >= 200 && status <= 500
    }
})

const request = async <T = any>(config: AxiosRequestConfig): Promise<MyResponseType<T>> => {
  try {
    // // console.log("post-token", config?.data?.token)
    // const lighgData = JSON.parse(localStorage.getItem('lightData') || '{}')
    // instance.defaults.headers.common['X-Auth-Token'] = config?.data?.token || _.get(lighgData, 'state.token', '')
    // instance.defaults.headers.common['X-Lang'] = localStorage.getItem('lang') === 'en'? 'en_US' : 'zh_CN'
    const { data } = await instance.request<MyResponseType<T>>(config)
    if (data.code === 10000) {
      // localStorage.setItem('token', 'EXPIRED')
    }
    return data
  } catch (err) {
    const message = 'Request Error'
    return {
      code: -1,
      message,
      success: false,
      fail: true,
      result: null as any,
    }
  }
}

export default request
