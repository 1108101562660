import React, { useEffect } from 'react'
import Home from './vitews/Home';
import './assets/style/index.scss';

function App() {
  const resizeRem = () => {
    const width = document.documentElement.clientWidth
    if (width >= 960 && width < 1920) {
      document.documentElement.style.fontSize = `${width / 14.4}px`
    } else if (width >= 1920) {
      // document.documentElement.style.fontSize = `${1920 / 14.4}px`
      document.documentElement.style.fontSize = `${width / 19.2}px`
    } else {
      document.documentElement.style.fontSize = `${width / 3.9}px`
    }
    
  }
  useEffect(() => {
    resizeRem()
    window.addEventListener('resize', () => resizeRem())
    return () => {
      window.removeEventListener('resize', () => resizeRem())
    }
  }, [])
  return (
    <div className="app-wapper">
      <Home />
    </div>
  );
}

export default App;
