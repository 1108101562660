import { IconProps } from '../type'

export const Telegram: React.FC<IconProps> = ({ color = '#fff', width = '.21rem', height ='.20rem' }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" className="telegram-svg" width="21" height="20" viewBox="0 0 21 20" fill="none" style={{ width: width, height: height }}>
      <path className="svg-border" fill-rule="evenodd" clip-rule="evenodd" d="M18.1933 1.95698C19.1249 1.60451 20.0744 2.42988 19.855 3.40141L16.7278 17.2504C16.5295 18.1287 15.4955 18.5156 14.7693 17.983L10.407 14.784L8.95442 16.2559C8.28749 16.9318 7.14026 16.6466 6.86742 15.7371L5.71798 11.9056L1.83845 10.4949C0.748899 10.0987 0.738937 8.56134 1.82326 8.15106L18.1933 1.95698ZM9.18437 13.8874L8.38041 13.2979C7.74108 12.829 7.69412 11.8911 8.28341 11.3607L12.8264 7.27201C13.1343 6.99491 13.6085 7.01987 13.8856 7.32775C14.1627 7.63564 14.1378 8.10986 13.8299 8.38695L9.51534 12.27L15.3487 16.5478L18.2894 3.52439L2.98436 9.31551L6.34855 10.5388C6.71966 10.6738 7.00518 10.9762 7.11865 11.3544L8.18298 14.9022L9.18437 13.8874Z" fill="white"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M8.19191 11.4518C8.22049 11.4203 8.25098 11.3899 8.28341 11.3607L12.8264 7.27195C13.1343 6.99486 13.6085 7.01982 13.8856 7.3277C14.1627 7.63558 14.1378 8.1098 13.8299 8.38689L9.51534 12.27L15.3487 16.5478L18.2894 3.52434L2.98436 9.31545L6.34855 10.5388C6.70036 10.6667 6.97525 10.9451 7.09957 11.296C7.10639 11.3152 7.11275 11.3347 7.11865 11.3543L8.18298 14.9021L9.18437 13.8874L8.38041 13.2978C8.3767 13.2951 8.37301 13.2923 8.36934 13.2896C7.77535 12.8442 7.70409 11.9895 8.19191 11.4518ZM7.78965 10.4597L11.4192 7.19307L5.85931 9.29682L6.69029 9.599C7.14412 9.76403 7.52684 10.0682 7.78965 10.4597ZM14.0044 6.21491C14.2358 6.31165 14.45 6.45991 14.6289 6.65873C15.2755 7.37713 15.2172 8.48363 14.4988 9.13019L11.0983 12.1907L14.7094 14.8389L16.9045 5.11756L14.0044 6.21491ZM17.8394 1.02164C19.5162 0.387184 21.2253 1.87285 20.8304 3.62162L17.7032 17.4706C17.3462 19.0517 15.485 19.7479 14.1779 18.7894L10.5121 16.1011L9.6662 16.9583C8.46572 18.1748 6.4007 17.6614 5.9096 16.0244L4.90441 12.6738L1.49671 11.4346C-0.464481 10.7215 -0.482413 7.95423 1.46937 7.21571L17.8394 1.02164ZM5.71798 11.9056L6.86742 15.7371C7.14026 16.6465 8.28749 16.9317 8.95442 16.2559L10.407 14.784L14.7693 17.983C15.4955 18.5155 16.5295 18.1287 16.7278 17.2503L19.855 3.40136C20.0744 2.42982 19.1249 1.60445 18.1933 1.95693L1.82326 8.151C0.738938 8.56129 0.7489 10.0987 1.83845 10.4949L5.71798 11.9056Z" fill="black"/>
    </svg>
  )
}

export const CakeTelegram: React.FC<IconProps> = ({ width = '.42rem', height ='.41rem' }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" className="cake-telegram-svg" width="42" height="41" viewBox="0 0 42 41" fill="none" style={{ width: width, height: height }}>
      <path className='svg-path' d="M40.6811 20.5549C37.6811 38.0549 32.1811 40.5549 20.6812 40.5549C9.18115 40.5549 4.68115 31.5547 0.681149 20.5549C-2.73628 11.1571 7.13446 -2.97885 20.6812 0.554884C32.1812 3.55473 43.6811 8.05489 40.6811 20.5549Z" fill="black"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M16.9029 28.6347L17.241 23.5329L26.5038 15.1861C26.9136 14.8128 26.4191 14.6317 25.8764 14.9574L14.4428 22.1824L9.4974 20.6136C8.43547 20.3126 8.42375 19.5771 9.73845 19.0461L29.0003 11.6166C29.8811 11.2185 30.7255 11.8335 30.3873 13.184L27.1071 28.6347C26.8778 29.7331 26.2139 29.9983 25.2979 29.4908L20.3043 25.8001L17.9042 28.1285C17.6267 28.4054 17.3973 28.6347 16.9029 28.6347Z" fill="white"/>
    </svg>
  )
}