import { IconProps } from '../type'

const Drow: React.FC<IconProps> = ({ color = '#fff', width = '.09rem', height ='.17rem' }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="9" height="17" viewBox="0 0 9 17" fill="none" style={{ width: width, height: height }}>
      <path d="M1 16L8 8.5L0.999999 1" stroke="#B2B2B2" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
  )
}

export default Drow
